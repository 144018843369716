<template>
  <div class="contact--profile">
    <div class="contact--info">
      <div class="contact-info--header">
        <thumbnail
          v-if="showAvatar"
          :src="contact.thumbnail"
          size="56px"
          :username="contact.name"
          :status="contact.availability_status"
        />
        <woot-button
          v-if="showCloseButton"
          :icon="closeIconName"
          class="clear secondary close-button--rtl"
          @click="onPanelToggle"
        />
      </div>

      <div class="contact--details">
        <div v-if="showAvatar" class="contact--name-wrap">
          <h3 class="sub-block-title contact--name">
            {{ contact.name }}
          </h3>
          <fluent-icon
            v-if="contact.created_at"
            v-tooltip="
              `${$t('CONTACT_PANEL.CREATED_AT_LABEL')} ${dynamicTime(
                contact.created_at
              )}`
            "
            icon="info"
            size="14"
          />
          <a
            :href="contactProfileLink"
            class="fs-default"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <woot-button
              size="tiny"
              icon="open"
              variant="clear"
              color-scheme="secondary"
            />
          </a>
        </div>

        <p v-if="additionalAttributes.description" class="contact--bio">
          {{ additionalAttributes.description }}
        </p>
        <social-icons :social-profiles="socialProfiles" />
        <div v-if="hasDescription">
          <div :class="className" v-html="descriptionFormatter" />
          <div
            v-if="isLong"
            class="description-size"
            @click="handleDescription"
          >
            {{
              showMore
                ? $t('CONVERSATION_SIDEBAR.SHOW_LESS')
                : $t('CONVERSATION_SIDEBAR.SHOW_MORE')
            }}
          </div>
        </div>
        <div v-if="!isGroup" class="contact--metadata">
          <contact-info-row
            :href="contact.email ? `mailto:${contact.email}` : ''"
            :value="contact.email"
            icon="mail"
            emoji="✉️"
            :title="$t('CONTACT_PANEL.EMAIL_ADDRESS')"
            show-copy
          />
          <contact-info-row
            :href="contact.phone_number ? `tel:${contact.phone_number}` : ''"
            :value="contact.phone_number"
            :icon="contact.whatsapp_valid ? 'whatsapp' : 'call'"
            emoji="📞"
            :title="$t('CONTACT_PANEL.PHONE_NUMBER')"
            show-copy
          />
          <contact-info-row
            v-if="contact.identifier"
            :value="contact.identifier"
            icon="contact-identify"
            emoji="🪪"
            :title="$t('CONTACT_PANEL.IDENTIFIER')"
          />
          <contact-info-row
            :value="additionalAttributes.company_name"
            icon="building-bank"
            emoji="🏢"
            :title="$t('CONTACT_PANEL.COMPANY')"
          />
          <contact-info-row
            v-if="location || additionalAttributes.location"
            :value="location || additionalAttributes.location"
            icon="map"
            emoji="🌍"
            :title="$t('CONTACT_PANEL.LOCATION')"
          />
        </div>
      </div>
      <div class="contact-actions">
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.NEW_MESSAGE')"
          title="$t('CONTACT_PANEL.NEW_MESSAGE')"
          class="new-message bg-primary"
          icon="chat"
          size="small"
          @click="handleConversations"
        />
        <woot-button
          v-tooltip="$t('EDIT_CONTACT.BUTTON_LABEL')"
          title="$t('EDIT_CONTACT.BUTTON_LABEL')"
          class="edit-contact"
          icon="edit"
          variant="smooth"
          size="small"
          @click="toggleEditModal"
        />
        <woot-button
          v-tooltip="$t('CONTACT_PANEL.MERGE_CONTACT')"
          title="$t('CONTACT_PANEL.MERGE_CONTACT')"
          class="merge-contact"
          icon="merge"
          variant="smooth"
          size="small"
          color-scheme="secondary"
          :disabled="uiFlags.isMerging"
          @click="openMergeModal"
        />
        <woot-button
          v-if="conversationId"
          v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
          class="send-contact"
          icon="send"
          emoji="🖊️"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="sendContact"
        />

        <woot-button
          v-if="permissionDeleteContact && !isGroup"
          v-tooltip="$t('DELETE_CONTACT.BUTTON_LABEL')"
          title="$t('DELETE_CONTACT.BUTTON_LABEL')"
          class="delete-contact"
          icon="delete"
          variant="smooth"
          size="small"
          color-scheme="alert"
          :disabled="uiFlags.isDeleting"
          @click="toggleDeleteModal"
        />
        <woot-button
          v-if="isGroup"
          v-tooltip="$t('GROUP_PARTICIPANT.TITLE')"
          title="$t('GROUP_PARTICIPANT.TITLE')"
          icon="people"
          variant="smooth"
          size="small"
          class="participants-group"
          color-scheme="secondary"
          @click="toggleParticipantsGroups"
        />
        <woot-button
          v-if="isGroup"
          v-tooltip="$t('GROUP_PARTICIPANT.ADD_TITLE')"
          title="$t('GROUP_PARTICIPANT.ADD_TITLE')"
          icon="add"
          variant="smooth"
          size="small"
          color-scheme="secondary"
          @click="toggleAddNewParticipantGroup"
        />
      </div>
      <edit-contact
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        @cancel="toggleEditModal"
      />
      <new-conversation
        v-if="contact.id"
        :show="showConversationModal"
        :contact-conversations="contactConversations"
        :contact="contact"
        @success="closeConversationModal"
        @cancel="toggleConversationModal"
      />
      <contact-merge-modal
        v-if="showMergeModal"
        :primary-contact="contact"
        :show="showMergeModal"
        @close="toggleMergeModal"
      />
    </div>
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
    <modal-participants-group
      v-if="showGroupParticipants"
      :data="groupParticipants"
      :conversation-info="conversationAttributes"
      @close="toggleParticipantsGroups"
    />
    <modal-add-new-participant
      v-if="showAddNewParticipant"
      :data="groupParticipants"
      :conversation-info="conversationAttributes"
      @close="toggleAddNewParticipantGroup"
    />
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from 'dashboard/mixins/time';
import ContactInfoRow from './ContactInfoRow';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import SocialIcons from './SocialIcons';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import wootConstants from 'dashboard/constants/globals';
import EditContact from './EditContact';
import NewConversation from './NewConversation';
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import { getCountryFlag } from 'dashboard/helper/flag';
import ModalParticipantsGroup from './ModalParticipantsGroup.vue';
import ModalAddNewParticipant from './ModalAddNewParticipant.vue';

export default {
  components: {
    ContactInfoRow,
    EditContact,
    Thumbnail,
    SocialIcons,
    NewConversation,
    ContactMergeModal,
    ModalParticipantsGroup,
    ModalAddNewParticipant,
  },
  mixins: [alertMixin, adminMixin, clickaway, timeMixin, messageFormatterMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    channelType: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeIconName: {
      type: String,
      default: 'chevron-right',
    },
    conversationId: {
      type: Number,
      default: undefined,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    message: {
      type: String,
      default: '',
    },
    toggleContact: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      showMore: false,
      showGroupParticipants: false,
      showAddNewParticipant: false,
      acceptChannelTypes: ['Channel::Api'],
      setPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      inboxes: 'inboxes/getInboxes',
      currentUser: 'getCurrentUser',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      groupParticipants: 'getGroupParticipants',
      groupParticipantsMeta: 'getGroupParticipantsMeta',
    }),
    className() {
      return this.showMore ? 'description-complete' : 'description';
    },
    isLong() {
      return this.conversation?.group?.description.length > 450;
    },
    descriptionFormatter() {
      if (this.conversation?.group?.description) {
        const formattedText = this.whatsappFormatter(
          this.conversation?.group?.description
        );
        return this.formatMessage(formattedText);
      }
      return '';
    },
    isGroup() {
      if (this?.conversation?.group) {
        return true;
      }
      return false;
    },
    hasDescription() {
      if (this?.conversation?.group?.description) {
        return true;
      }
      return false;
    },
    conversationAttributes() {
      return this.conversation;
    },
    contactConversations() {
      const currentId = this.currentUser?.id;
      const currentConversations = this.$store.getters[
        'contactConversations/getContactConversation'
      ](
        this.contact?.id,
        currentId,
        this.isLimitedAgent,
        this.isPermissionSeeAllTeamConversation
      );
      return currentConversations;
    },
    inbox() {
      if (this.inboxes.length === 1) return this.inboxes[0];

      return false;
    },
    currentInboxId() {
      if (this.inbox) return this.inbox.id;

      return this.$route.params.inboxId;
    },
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    openConversations() {
      if (this.currentInboxId) {
        return this.contactConversations.filter(
          conversation =>
            conversation.inbox_id === this.currentInboxId &&
            conversation.status !== 'resolved' &&
            conversation?.meta?.channel !== 'Channel::Internal'
        );
      }

      return this.contactConversations.filter(
        conversation => conversation.status !== 'resolved'
      );
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    // Delete Modal
    confirmDeleteMessage() {
      return ` ${this.contact.name}?`;
    },

    permissionDeleteContact() {
      return this.currentUser.permissions.delete_contact;
    },
  },

  watch: {
    $route: {
      handler() {
        this.fetchDataGroup();
      },
    },
  },
  beforeMount() {
    this.fetchData();
    this.fetchDataGroup();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('contactConversations/get', this.contact.id);
    },

    async fetchDataGroup() {
      if (this.conversation?.group) {
        const group = this.conversation?.group;
        const payload = {
          groupId: group.id,
          accountId: group.account_id,
          page: 1,
        };
        await this.$store.dispatch('fetchGroupParticipants', payload);
      }
    },

    whatsappFormatter(text) {
      const {
        ITALIC_PATTERN,
        ITALIC_CHARACTER,
        BOLD_PATTERN,
        BOLD_CHARACTER,
        ITALIC_BOLD_PATTERN,
        ITALIC_BOLD_CHARACTER,
        ITALIC_BOLD_CHARACTER_REVERSE,
      } = wootConstants.WHATSAPP_FORMATTER_REVERSE;
      text = text.replace(
        ITALIC_BOLD_PATTERN,
        `${ITALIC_BOLD_CHARACTER}$1${ITALIC_BOLD_CHARACTER_REVERSE}`
      );
      text = text.replace(BOLD_PATTERN, `${BOLD_CHARACTER}$1${BOLD_CHARACTER}`);
      text = text.replace(
        ITALIC_PATTERN,
        `${ITALIC_CHARACTER}$1${ITALIC_CHARACTER}`
      );
      return text;
    },
    closeConversationModal() {
      this.toggleConversationModal();

      this.$emit('toggle-contacts-view');
    },
    toggleAddNewParticipantGroup() {
      this.showAddNewParticipant = !this.showAddNewParticipant;
    },
    toggleParticipantsGroups() {
      this.showGroupParticipants = !this.showGroupParticipants;
    },
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    isSingleNonOfficialWhatsappInbox() {
      return (
        this.inbox &&
        this.inbox?.is_whatsapp &&
        this.acceptChannelTypes.includes(this.inbox?.channel_type)
      );
    },
    handleDescription() {
      this.showMore = !this.showMore;
    },
    handleConversations() {
      if (this.isSingleNonOfficialWhatsappInbox()) {
        switch (this.openConversations.length) {
          case 0:
            return this.createConversation({
              inboxId: this.inbox.id,
              contactId: this.contact.id,
              assigneeId: this.currentUser.id,
            });
          case 1:
            return this.showAlert(
              this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATION')
            );
          default:
            return this.showAlert(
              this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATIONS')
            );
        }
      } else {
        this.toggleConversationModal();
        return undefined;
      }
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    toggleContactsModal() {
      this.$emit('toggle-contacts-view');
    },
    sendContact() {
      this.toggleContact(this.contact);
      this.onSend();
      this.$emit('toggle-contacts-view');
      this.closeDelete();
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    async createConversation(payload) {
      try {
        const data = await this.onSubmit(payload);
        this.toggleContactsModal();

        this.$router.push(
          `/app/accounts/${data.account_id}/conversations/${data.id}`
        );
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },
    async onSubmit(contactItem) {
      const data = await this.$store.dispatch(
        'contactConversations/create',
        contactItem
      );
      return data;
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));
        if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({ name: 'contacts_dashboard' });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.description {
  max-height: 29vh;
  overflow-y: hidden;
}
.description-complete {
  max-height: 100vh;
}

.description-size {
  color: #07c4dc;
  cursor: pointer;
  width: fit-content;
}
.description-size:hover {
  text-decoration: underline;
}

.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: left;
}

.contact-info--header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.contact--name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 var(--space-smaller);

  a {
    color: var(--color-body);
  }
}

.contact--metadata {
  margin-bottom: var(--space-slab);
}

.contact-actions {
  margin-top: var(--space-small);
}

.contact-actions {
  display: flex;
  align-items: center;
  width: 100%;

  .new-message,
  .edit-contact,
  .merge-contact,
  .send-contact,
  .participants-group,
  .delete-contact {
    margin-right: var(--space-small);
  }
}

.merge-summary--card {
  padding: var(--space-normal);
}

.contact--bio {
  word-wrap: break-word;
}

.button--contact-menu {
  position: absolute;
  right: var(--space-normal);
  top: 0;
}
</style>
