<template>
  <div class="view-box fill-height">
    <banner
      v-if="!currentChat.can_reply"
      color-scheme="alert"
      :banner-message="replyWindowBannerMessage"
      :href-link="replyWindowLink"
      :href-link-text="replyWindowLinkText"
    />

    <banner
      v-if="isATweet"
      color-scheme="gray"
      :banner-message="tweetBannerText"
      :has-close-button="hasSelectedTweetId"
      @close="removeTweetSelection"
    />

    <div class="sidebar-toggle__wrap">
      <button
        variant="smooth"
        :class="isRightOrLeftIcon"
        size="tiny"
        color-scheme="secondary"
        class="sidebar-toggle--button"
        @click="onToggleContactPanel"
      >
        <span>
          {{ $t('CONVERSATION.NAVIGATION_BUTTONS.DETAILS') }}
        </span>
        <emoji-or-icon class="icon" icon="arrow-chevron-right" icon-size="14" />
        <!-- <i class="arrow-chevron-right"></i> -->
      </button>
    </div>

    <div id="conversation-panel" class="conversation-panel">
      <ul class="conversation-panel">
        <div
          v-if="currentChatIndex > 0"
          id="preview-button"
          class="text-center"
        >
          <woot-button size="tiny" @click="handlePreviewConversation">
            {{ $t('CONVERSATION.NAVIGATION_BUTTONS.PREVIOUS') }}
          </woot-button>
        </div>
        <transition name="slide-up">
          <li v-if="shouldShowSpinner()" class="spinner--container">
            <span class="spinner message" />
          </li>
        </transition>
        <message
          v-for="message in getReadMessages"
          :key="message.id"
          class="message--read ph-no-capture"
          data-clarity-mask="True"
          :data="message"
          :is-a-tweet="isATweet"
          :is-a-whatsapp-not-official="isWhatsappNotOfficial"
          :has-instagram-story="hasInstagramStory"
          :is-web-widget-inbox="isAWebWidgetInbox"
          :current-chat-assignee="CurrentChatAssigneeData"
          @external-id="handleExternalId"
        />
        <li v-show="unreadMessageCount != 0" class="unread--toast">
          <span class="text-uppercase bg-primary">
            {{ unreadMessageCount }}
            {{
              unreadMessageCount > 1
                ? $t('CONVERSATION.UNREAD_MESSAGES')
                : $t('CONVERSATION.UNREAD_MESSAGE')
            }}
          </span>
        </li>
        <message
          v-for="message in getUnReadMessages"
          :key="message.id"
          class="message--unread ph-no-capture"
          data-clarity-mask="True"
          :data="message"
          :is-a-tweet="isATweet"
          :is-a-whatsapp-not-official="isWhatsappNotOfficial"
          :has-instagram-story="hasInstagramStory"
          :is-web-widget-inbox="isAWebWidgetInbox"
          :current-chat-assignee="CurrentChatAssigneeData"
          @external-id="handleExternalId"
        />
        <div
          v-if="currentChatIndex !== sortContactConversations.length - 1"
          id="next-button"
          class="text-center"
        >
          <woot-button size="tiny" @click="handleNextConversation">
            {{ $t('CONVERSATION.NAVIGATION_BUTTONS.NEXT') }}
          </woot-button>
        </div>
      </ul>
      <div id="scroll-bottom-panel" class="text-center">
        <woot-button
          icon="chevron-down"
          size="small"
          @click="handleScrollToBottom"
        />
      </div>
    </div>
    <div
      class="conversation-footer"
      :class="{ 'modal-mask': isPopoutReplyBox }"
    >
      <div v-if="isAnyoneTyping" class="typing-indicator-wrap">
        <div class="typing-indicator">
          {{ typingUserNames }}
          <img
            class="gif"
            src="~dashboard/assets/images/typing.gif"
            alt="Someone is typing"
          />
        </div>
      </div>
      <reply-box
        :conversation-id="currentChat.id"
        :is-a-tweet="isATweet"
        :to-be-replied="getExternalId"
        :selected-tweet="selectedTweet"
        :popout-reply-box.sync="isPopoutReplyBox"
        @click="showPopoutReplyBox"
        @external-id="handleExternalId"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplyBox from './ReplyBox';
import Message from './Message';
import conversationMixin, {
  filterDuplicateSourceMessages,
} from '../../../mixins/conversations';
import Banner from 'dashboard/components/ui/Banner.vue';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { REPLY_POLICY } from 'shared/constants/links';
import inboxMixin from 'shared/mixins/inboxMixin';
import { calculateScrollTop } from './helpers/scrollTopCalculationHelper';
import { isEscape } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import alertMixin from 'shared/mixins/alertMixin';
import EmojiOrIcon from 'shared/components/EmojiOrIcon';
import agentMixin from '../../../mixins/agentMixin';

export default {
  components: {
    Message,
    ReplyBox,
    Banner,
    EmojiOrIcon,
  },
  mixins: [
    conversationMixin,
    inboxMixin,
    eventListenerMixins,
    alertMixin,
    agentMixin,
  ],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      replyObj: {},
      icon_row: '',
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      scrollTopBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      isPopoutReplyBox: false,
      currentChatIndex: null,
      sortContactConversations: [],
    };
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      loadingChatList: 'getChatListLoadingStatus',
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    CurrentChatAssigneeData() {
      return this.currentChat;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    hasSelectedTweetId() {
      return !!this.selectedTweetId;
    },
    tweetBannerText() {
      return !this.selectedTweetId
        ? this.$t('CONVERSATION.SELECT_A_TWEET_TO_REPLY')
        : `
            ${this.$t('CONVERSATION.REPLYING_TO')}
            ${this.selectedTweet.content}` || '';
    },
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }

      return '';
    },
    getMessages() {
      let messages = this.currentChat.messages || [];

      if (
        !this.currentUser?.permissions?.see_private_message ||
        !this.inbox?.enable_private_notes
      ) {
        messages = messages.filter(msg => !msg.private);
      }

      if (this.isAWhatsAppChannel) {
        return filterDuplicateSourceMessages(messages);
      }
      return messages;
    },
    getReadMessages() {
      return this.readMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },
    getUnReadMessages() {
      return this.unReadMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },

    isATweet() {
      return this.conversationType === 'tweet';
    },

    hasInstagramStory() {
      return this.conversationType === 'instagram_direct_message';
    },

    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.currentChat;
        const [selectedMessage] = messages.filter(
          message => message.id === this.selectedTweetId
        );
        return selectedMessage || {};
      }
      return '';
    },
    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'arrow_right';
      }
      return 'arrow_left';
    },
    getExternalId() {
      return this.replyObj;
    },
    getLastSeenAt() {
      const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
      return contactLastSeenAt;
    },

    replyWindowBannerMessage() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY');
      }
      if (this.isAPIInbox) {
        const { additional_attributes: additionalAttributes = {} } = this.inbox;
        if (additionalAttributes) {
          const {
            agent_reply_time_window_message: agentReplyTimeWindowMessage,
          } = additionalAttributes;
          return agentReplyTimeWindowMessage;
        }
        return '';
      }
      return this.$t('CONVERSATION.CANNOT_REPLY');
    },
    replyWindowLink() {
      if (this.isAWhatsAppChannel) {
        return REPLY_POLICY.FACEBOOK;
      }
      if (!this.isAPIInbox) {
        return REPLY_POLICY.TWILIO_WHATSAPP;
      }
      return '';
    },
    replyWindowLinkText() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.WHATSAPP_CLICK_HERE');
      }
      if (!this.isAPIInbox) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW');
      }
      return '';
    },
    unreadMessageCount() {
      return this.currentChat.unread_count;
    },
    isWhatsappNotOfficial() {
      return this.isNotOfficialWhatsapp;
    },
  },
  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }
      this.fetchAllAttachmentsFromCurrentChat();
      this.selectedTweetId = null;
    },
  },

  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
  },

  mounted() {
    this.addScrollListener();
    this.fetchAllAttachmentsFromCurrentChat();
  },

  beforeDestroy() {
    this.removeBusListeners();
    this.removeScrollListener();
  },

  beforeUpdate() {
    this.currentChatIndex = this.getCurrentChatIndex();
  },

  methods: {
    shouldShowSpinner() {
      return (
        (this.currentChat && this.currentChat.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },
    handleExternalId(value) {
      this.replyObj = value;
    },
    previewCurrentConversationId() {
      let previewConversationId = this.currentChatIndex - 1;

      if (previewConversationId >= 0)
        return this.sortContactConversations[previewConversationId].id;

      return undefined;
    },
    nextCurrentConversationId() {
      let nextConversationId = this.currentChatIndex + 1;
      if (nextConversationId < this.sortContactConversations.length) {
        return this.sortContactConversations[nextConversationId].id;
      }

      return undefined;
    },
    getCurrentChatIndex() {
      const currentId = this.currentUser?.id;
      let contactConversations = this.$store.getters[
        'contactConversations/getContactConversation'
      ](
        this.currentChat.meta.sender.id,
        currentId,
        this.isLimitedAgent,
        this.isPermissionSeeAllTeamConversation
      );
      this.sortContactConversations = contactConversations.sort(
        (a, b) => a.id - b.id
      );
      return contactConversations.findIndex(
        contactConversation => contactConversation.id === this.currentChat.id
      );
    },
    handlePreviewConversation() {
      if (this.previewCurrentConversationId())
        this.$router.push(
          `/app/accounts/${
            this.currentAccountId
          }/conversations/${this.previewCurrentConversationId()}`
        );
    },
    handleNextConversation() {
      if (this.nextCurrentConversationId())
        this.$router.push(
          `/app/accounts/${
            this.currentAccountId
          }/conversations/${this.nextCurrentConversationId()}`
        );
    },
    fetchAllAttachmentsFromCurrentChat() {
      this.$store.dispatch('fetchAllAttachments', this.currentChat.id);
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
      bus.$off(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
    },
    setSelectedTweet(tweetId) {
      this.selectedTweetId = tweetId;
    },
    onScrollToMessage({ messageId = '' } = {}) {
      this.$nextTick(() => {
        const messageElement = document.getElementById('message' + messageId);
        if (messageElement) {
          messageElement.scrollIntoView({ behavior: 'smooth' });
          this.fetchPreviousMessages();
        } else {
          this.scrollToBottom();
        }
      });
      this.makeMessagesRead();
    },
    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('ul.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      let relevantMessages = [];
      if (this.unreadMessageCount > 0) {
        // capturing only the unread messages
        relevantMessages = this.conversationPanel.querySelectorAll(
          '.message--unread'
        );
      } else {
        // capturing last message from the messages list
        relevantMessages = Array.from(
          this.conversationPanel.querySelectorAll('.message--read')
        ).slice(-1);
      }
      this.conversationPanel.scrollTop = calculateScrollTop(
        this.conversationPanel.scrollHeight,
        this.$el.scrollHeight,
        relevantMessages
      );
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },
    handleScrollToBottom() {
      this.conversationPanel.scrollTop = this.heightBeforeLoad;
    },
    async fetchPreviousMessages(scrollTop = 0) {
      this.setScrollParams();
      const shouldLoadMoreMessages =
        this.currentChat.dataFetched === true &&
        !this.listLoadingStatus &&
        !this.isLoadingPrevious;

      if (
        scrollTop < 100 &&
        !this.isLoadingPrevious &&
        shouldLoadMoreMessages
      ) {
        this.isLoadingPrevious = true;
        try {
          await this.$store.dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.currentChat.messages[0].id,
          });
          const heightDifference =
            this.conversationPanel.scrollHeight - this.heightBeforeLoad;
          this.conversationPanel.scrollTop =
            this.scrollTopBeforeLoad + heightDifference;
          this.setScrollParams();
        } catch (error) {
          // Ignore Error
        } finally {
          this.isLoadingPrevious = false;
        }
      }
    },

    handleScroll(e) {
      bus.$emit(BUS_EVENTS.ON_MESSAGE_LIST_SCROLL);
      this.fetchPreviousMessages(e.target.scrollTop);
    },

    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
  },
};
</script>

<style scoped lang="scss">
#scroll-bottom-panel {
  position: absolute;
  bottom: 30px;
  right: 30px;
  border-radius: 50% !important;
  .button {
    background-color: var(--g-20) !important;
  }
}
#next-button {
  margin-top: 1%;
  margin-bottom: 1%;
  .button {
    background-color: var(--g-20) !important;
  }
}
#preview-button {
  margin-top: 1%;
  margin-bottom: 1%;
  .button {
    background-color: var(--g-20) !important;
  }
}
.view-box {
  position: relative;
}
.spinner--container {
  min-height: var(--space-jumbo);
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 0;
}

.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      max-height: 40rem;
    }

    .reply-box {
      border: 1px solid var(--color-border);
      max-width: 120rem;
      width: 70%;
    }

    .reply-box .reply-box__top {
      position: relative;
      min-height: 44rem;
    }

    .reply-box__top .input {
      min-height: 44rem;
    }

    .emoji-dialog {
      position: fixed;
      left: unset;
      position: absolute;
      bottom: var(--space-smaller);
    }
  }
}
.sidebar-toggle__wrap {
  display: flex;
  justify-content: flex-end;

  .sidebar-toggle--button {
    display: flex;
    gap: 5px;
    font-size: 12px;
    font-weight: bold;
    align-items: center;
    width: 100px;
    height: 22px;
    position: fixed;
    margin-right: calc(0% - 39px);
    transform: rotate(-90deg);
    border-radius: 10px 10px 0px 0px;
    padding: 5px 15px !important;
    color: #fff !important;
    background-color: #01c6d8;
    top: 15.8rem;
    z-index: var(--z-index-low);
    border: 1px solid var(--color-border-light);
    border-right: 0;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
  }

  .arrow_right svg {
    transform: rotate(90deg);
    color: var(--s-700) !important;
  }

  .arrow_left svg {
    transform: rotate(-90deg);
    color: var(--s-700) !important;
  }
}
</style>
