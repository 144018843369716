<template>
  <div class=" app-wrapper">
    <header-app @open-key-shortcut-modal="toggleKeyShortcutModal" />
    <div class="row app-container">
      <sidebar
        :route="currentRoute"
        :show-secondary-sidebar="isSidebarOpen"
        @open-notification-panel="openNotificationPanel"
        @toggle-account-modal="toggleAccountModal"
        @close-key-shortcut-modal="closeKeyShortcutModal"
        @show-add-label-popup="showAddLabelPopup"
        @toggle-contacts-view="onToggleContactsModal"
      />
      <section class="app-content columns">
        <router-view />
        <command-bar />
        <account-selector
          :show-account-modal="showAccountModal"
          @close-account-modal="toggleAccountModal"
          @show-create-account-modal="openCreateAccountModal"
        />
        <add-account-modal
          :show="showCreateAccountModal"
          @close-account-create-modal="closeCreateAccountModal"
        />
        <woot-key-shortcut-modal
          :show.sync="showShortcutModal"
          @close="closeKeyShortcutModal"
          @clickaway="closeKeyShortcutModal"
        />
        <notification-panel
          v-if="isNotificationPanel"
          @close="closeNotificationPanel"
        />
        <woot-modal
          :show.sync="showAddLabelModal"
          :on-close="hideAddLabelPopup"
        >
          <add-label-modal @close="hideAddLabelPopup" />
        </woot-modal>
        <woot-modal
          :show.sync="toggleContactsModal"
          :on-close="hideContactsModal"
          size="modal-xl"
        >
          <div class="column content-box">
            <div class="row">
              <contacts-view @toggle-contacts-view="onToggleContactsModal" />
            </div>
          </div>
        </woot-modal>
      </section>
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/layout/Sidebar';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import wootConstants from 'dashboard/constants/globals';
import ContactsView from './contacts/components/ContactsView.vue';
import { mapGetters } from 'vuex';

import HeaderApp from './HeaderApp.vue';

export default {
  components: {
    Sidebar,
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
    ContactsView,
    HeaderApp,
  },
  mixins: [uiSettingsMixin],
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      toggleContactsModal: false,
      displayLayoutType: '',
      showOptionsMenu: false,
      showOptionsMenuStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
    currentRoute() {
      return ' ';
    },
    isSidebarOpen() {
      const { path: currentRoute } = this.$route;
      const {
        show_secondary_sidebar,
        is_contact_sidebar_open,
        show_secondary_sidebar_campaigns,
        show_secondary_sidebar_reports,
        show_secondary_sidebar_config,
      } = this.uiSettings;

      if(
        !this.uiSettings.hasOwnProperty('show_secondary_sidebar') && 
        currentRoute.includes('conversation')
      ) {
        this.updateUISettings({
          show_secondary_sidebar: false,
        });
        return false
      } 

      if(currentRoute.includes('conversation') && !currentRoute.includes('reports/conversation')){
        return show_secondary_sidebar;
      }
      else if(currentRoute.includes('inbox/')){
        return show_secondary_sidebar;
      }else if(currentRoute.includes('contacts')){
        return is_contact_sidebar_open;
      }else if (currentRoute.includes('campaigns')){
        return show_secondary_sidebar_campaigns;
      }else if(currentRoute.includes('reports')){
        return show_secondary_sidebar_reports;
      }else{
        return show_secondary_sidebar_config;
      }
      
    },
    previouslyUsedDisplayType() {
      const {
        previously_used_conversation_display_type: conversationDisplayType,
      } = this.uiSettings;
      return conversationDisplayType;
    },
    previouslyUsedSidebarView() {
      const {
        previously_used_sidebar_view: showSecondarySidebar,
      } = this.uiSettings;
      return showSecondarySidebar;
    },
  },
  watch: {
    displayLayoutType() {
      const { LAYOUT_TYPES } = wootConstants;
      this.updateUISettings({
        conversation_display_type:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? LAYOUT_TYPES.EXPANDED
            : this.previouslyUsedDisplayType,
        show_secondary_sidebar:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? false
            : this.previouslyUsedSidebarView,
      });
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },

  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleOptionsStatus() {
      this.showOptionsMenuStatus = !this.showOptionsMenuStatus;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
      this.isNotificationPanel = true;
    },
    handleResize() {
      const { SMALL_SCREEN_BREAKPOINT, LAYOUT_TYPES } = wootConstants;
      let throttled = false;
      const delay = 150;

      if (throttled) {
        return;
      }
      throttled = true;

      setTimeout(() => {
        throttled = false;
        if (window.innerWidth <= SMALL_SCREEN_BREAKPOINT) {
          this.displayLayoutType = LAYOUT_TYPES.EXPANDED;
        } else {
          this.displayLayoutType = LAYOUT_TYPES.CONDENSED;
        }
      }, delay);
    },
    toggleSidebar() {
      const { path: currentRoute } = this.$route;

      if(currentRoute.includes('conversation') && !currentRoute.includes('reports/conversation')){
        this.updateUISettings({
          show_secondary_sidebar: !this.isSidebarOpen,
          previously_used_sidebar_view: !this.isSidebarOpen,
        });
      }else if(currentRoute.includes('inbox/')){
         this.updateUISettings({
          show_secondary_sidebar: !this.isSidebarOpen,
          previously_used_sidebar_view: !this.isSidebarOpen,
        });
      }else if(currentRoute.includes('contacts')){
        this.updateUISettings({
          is_contact_sidebar_open: !this.isSidebarOpen,
        });
      }else if(currentRoute.includes('campaigns')){
        this.updateUISettings({
          show_secondary_sidebar_campaigns: !this.isSidebarOpen,
        });
      }else if(currentRoute.includes('reports')){
        this.updateUISettings({
          show_secondary_sidebar_reports: !this.isSidebarOpen,
        });
      }else{
        this.updateUISettings({
          show_secondary_sidebar_config: !this.isSidebarOpen,
        });
      }
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
    onToggleContactsModal() {
      this.toggleContactsModal = !this.toggleContactsModal;
    },
    hideContactsModal() {
      this.toggleContactsModal = false;
    },
  },
};
</script>

<style scoped>
.app-container {
  height: calc(100vh - 55px);
}

.pac-container {
  z-index: 9999 !important;
}
</style>
