<template>
    <div class="agent-table-container">
      <ve-table
        max-height="calc(100vh - 35rem)"
        :fixed-header="true"
        :columns="columns"
        :table-data="tableData"
      />
      <div v-if="isLoading" class="agents-loader">
        <spinner />
        <span>{{
          $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')
        }}</span>
      </div>
      <empty-state
        v-else-if="!isLoading && !agentMetrics.length"
        :title="$t('OVERVIEW_REPORTS.LABEL_TABLE.NO_AGENTS')"
      />
      <div class="table-pagination">
        <ve-pagination
          :total="labelCount"
          :page-index="pageIndex"
          :page-size="10"
          :page-size-option="[10]"
          @on-page-number-change="onPageNumberChange"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { VeTable, VePagination } from 'vue-easytable';
  import Spinner from 'shared/components/Spinner.vue';
  import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
  import rtlMixin from 'shared/mixins/rtlMixin';
  import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import message from '../../../../api/inbox/message';
  
  export default {
    name: 'LabelTable',
    components: {
      EmptyState,
      Spinner,
      VeTable,
      VePagination,
    },
    mixins: [rtlMixin],
    props: {
      agentMetrics: {
        type: Object,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      pageIndex: {
        type: Number,
        default: 1,
      },
    },
    computed: {
      labelCount() {
        return this.agentMetrics?.get_labels_count
      },
      labelTable() {
        return this.agentMetrics?.label_table
      },
      tableData() {
        return this.agentMetrics?.label_table.map(agent => {
          const messageLink = `${window.chatwootConfig.hostURL}/app/accounts/${agent.account_id}/conversations/${agent.display_id}?messageId=${agent.message_id}`;
          return {
            contact: agent.contact_name,
            agent: agent.assignee_name,
            status: agent.status === 'resolved' ? this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.SOLVED'
          ) : this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.NOT_SOLVED'
          ),
            link: messageLink,
            message_id: agent.message_id,
            inbox: agent.inbox_name,
          };
        });
      },
      columns() {
        return [
          {
            field: 'contact',
            key: 'contact',
            title: this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.TABLE_HEADER.CONTACT'
          ),
            fixed: 'left',
            align: this.isRTLView ? 'right' : 'left',
            width: 10,
            renderBodyCell: ({ row }) => (
              <div class="row-user-block">
                <Thumbnail
                  src={''}
                  size="32px"
                  username={row.contact ? row.contact : "---"}
                  status={row.status}
                />
                <div class="user-block">
                  <h6 class="title text-truncate">{row.contact ? row.contact : "---"}</h6>
                </div>
              </div>
            ),
          },
          {
            field: 'agent',
            key: 'agent',
            title: this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.TABLE_HEADER.AGENT'
          ),
            align: this.isRTLView ? 'right' : 'left',
            width: 10,
            renderBodyCell: ({ row }) => (
              <div class="row-user-block">
                <Thumbnail
                  src={''}
                  size="32px"
                  username={row.agent ? row.agent : "---"}
                  status={row.status}
                />
                <div class="user-block">
                  <h6 class="title text-truncate">{row.agent ? row.agent : "---"}</h6>
                </div>
              </div>
            ),
          },
          {
            field: 'inbox',
            key: 'inbox',
            title: this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.TABLE_HEADER.INBOX'
          ),
            align: this.isRTLView ? 'right' : 'left',
            width: 10,
          },
          {
            field: 'status',
            key: 'status',
            title: this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.TABLE_HEADER.STATUS'
          ),
            align: this.isRTLView ? 'right' : 'left',
            width: 10,
          },
          {
            field: 'link',
            key: 'link',
            title: this.$t(
            'OVERVIEW_REPORTS.LABEL_TABLE.TABLE_HEADER.LINK'
          ),
            align: this.isRTLView ? 'right' : 'left',
            width: 10,
            renderBodyCell: ({ row }) => (
              <div class="row-user-block">
                <a href={row.link}>{row.message_id}</a>
              </div>
            ),
          },
        ];
      },
    },
    methods: {
      onPageNumberChange(pageIndex) {
        this.$emit('page-change', pageIndex);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .agent-table-container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: column;
    flex: 1;
  
    .ve-table {
      &::v-deep {
        th.ve-table-header-th {
          font-size: var(--font-size-mini) !important;
          padding: var(--space-small) var(--space-two) !important;
        }
  
        td.ve-table-body-td {
          padding: var(--space-one) var(--space-two) !important;
        }
      }
    }
  
    &::v-deep .ve-pagination {
      background-color: transparent;
    }
  
    &::v-deep .ve-pagination-select {
      display: none;
    }
  
    .row-user-block {
      align-items: center;
      display: flex;
      text-align: left;
  
      .user-block {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        min-width: 0;
        margin: 0 var(--space-small);
  
        .title {
          font-size: var(--font-size-small);
          margin: var(--zero);
          line-height: 1.2;
        }
        .sub-title {
          font-size: var(--font-size-mini);
        }
      }
    }
  
    .table-pagination {
      margin-top: var(--space-normal);
      text-align: right;
    }
  }
  
  .agents-loader {
    align-items: center;
    display: flex;
    font-size: var(--font-size-default);
    justify-content: center;
    padding: var(--space-large);
  }
  </style>
  