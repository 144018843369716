<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType"  @change="changeTypeRole">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>

        <div class="medium-12 columns">
          <label class="medium-12 columns">
            <div class="enter-to-send--checkbox">
              <label>
                {{ $t('AGENT_MGMT.PERMISSIONS.TITLE') }}
              </label>
            </div>
          </label>
        </div>

        <div v-if="agentType === 'supervisor' || agentType === 'agent'">
          <div v-for="permision in permissions" :key="permision.name"  class="medium-12 columns">
            <label class="medium-12 columns">
              <div>
                <input
                  v-model="permissionsSelected[permision.name]"
                  type="checkbox"
                />
                <label>
                  {{ permision.label }}
                </label>
              </div>
            </label>
          </div>
        </div>

        <div v-if="agentType === 'administrator'" class="medium-12 columns">
          <label class="medium-12 columns">
            <div class="enter-to-send--checkbox">
              <label class="full-access">
                {{ this.$t('AGENT_MGMT.PERMISSIONS.FULL_ACCESS') }}
              </label>
            </div>
          </label>
        </div>

        <div class="medium-12 columns">
          <label v-if="isAdmin">
            {{ $t('PROFILE_SETTINGS.FORM.WALLET.LABEL') }}
            <select v-model="walletStatus">
              <option value="true" >{{ $t('PROFILE_SETTINGS.FORM.WALLET.ACTIVE') }}</option>
              <option value="false" >{{ $t('PROFILE_SETTINGS.FORM.WALLET.DISABLED') }}</option>
            </select>
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentAvailability.$error }">
            {{ $t('PROFILE_SETTINGS.FORM.AVAILABILITY.LABEL') }}
            <select v-model="agentAvailability">
              <option
                v-for="role in availabilityStatuses"
                :key="role.value"
                :value="role.value"
              >
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentAvailability.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 modal-footer">
          <div class="medium-6 columns">
            <woot-submit-button
              class="bg-primary"
              :disabled="
                $v.agentType.$invalid ||
                  $v.agentName.$invalid ||
                  uiFlags.isUpdating
              "
              :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
              :loading="uiFlags.isUpdating"
            />
            <button class="button clear btn-primary" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
          <div class="medium-6 columns text-right">
            <woot-button
              class="btn-primary"
              icon="lock-closed"
              variant="clear"
              @click.prevent="resetPassword"
            >
              {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import Auth from '../../../../api/auth';
import wootConstants from 'dashboard/constants/globals';
import isAdmin from '../../../../mixins/isAdmin';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [isAdmin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    availability: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      required: true,
    },
    agentWalletStatus: {
      type: Boolean,
      default: true,
    },
    agentPermissions: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      roles: [
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
        {
          name: 'supervisor',
          label: 'Supervisor',
        },
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
      ],
      agentName: this.name,
      agentType: this.type,
      agentCredentials: {
        email: this.email,
      },
      show: true,
      agentAvailability: this.availability,
      walletStatus: this.agentWalletStatus.toString(),
      permissionsAgent: [
        { name: 'create_global_shortcut', label: this.$t('AGENT_MGMT.PERMISSIONS.CREATE_GLOBAL_SHORTCUT_SETTINGS')},
        { name: 'see_private_message', label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_PRIVATE_MESSAGE_SETTINGS')},
        { name: 'assign_to_me', label: this.$t('AGENT_MGMT.PERMISSIONS.ASSIGN_TO_ME')},
        { name: 'see_all_conversations', label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_ALL_CONVERSATIONS')},
        { name: 'see_conversations_team', label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_CONVERSATIONS_TEAM')},
        { name: 'see_bot_conversation', label:this.$t('AGENT_MGMT.PERMISSIONS.SEE_BOT_CONVERSATION')},
        {name: 'delete_crm_observation', label:this.$t('AGENT_MGMT.PERMISSIONS.DELETE_CRM_OBSERVATION')},
      ],
      permissionsSupervisor: [
        { name: 'dashboard', label: this.$t('AGENT_MGMT.PERMISSIONS.DASHBOARD')},
        { name: 'contacts', label: this.$t('AGENT_MGMT.PERMISSIONS.CONTACTS')},
        { name: 'campaigns', label: this.$t('AGENT_MGMT.PERMISSIONS.CAMPAIGNS')},
        { name: 'help_center', label:  this.$t('AGENT_MGMT.PERMISSIONS.HELP_CENTER')},
        { name: 'reports', label:  this.$t('AGENT_MGMT.PERMISSIONS.REPORTS')},
        { name: 'agent_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.AGENT_SETTINGS')},
        { name: 'team_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.TEAM_SETTINGS')},
        { name: 'markers_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.MARKERS_SETTINGS')},
        { name: 'custom_attributes_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.CUSTOM_ATTRIBUTES_SETTINGS')},
        { name: 'automations_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.AUTOMATIONS_SETTINGS')},
        { name: 'macros_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.MACROS_SETTINGS')},
        { name: 'shortcuts_settings', label: this.$t('AGENT_MGMT.PERMISSIONS.SHORTCUTS_SETTINGS')},
        { name: 'create_global_shortcut', label: this.$t('AGENT_MGMT.PERMISSIONS.CREATE_GLOBAL_SHORTCUT_SETTINGS')},
        { name: 'see_private_message', label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_PRIVATE_MESSAGE_SETTINGS')},
        { name: 'team_chat_only', label:this.$t('AGENT_MGMT.PERMISSIONS.VIEW_TEAM')},
        { name: 'inboxes', label: this.$t('AGENT_MGMT.PERMISSIONS.INBOXES')},
        { name: 'delete_inbox', label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_INBOX')},
        { name: 'delete_agent', label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_AGENT')},
        { name: 'delete_team', label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_TEAM')},
        { name: 'integrations', label: this.$t('AGENT_MGMT.PERMISSIONS.INTEGRATIONS')},
        { name: 'applications', label: this.$t('AGENT_MGMT.PERMISSIONS.APPLICATIONS')},
        { name: 'audit_records', label: this.$t('AGENT_MGMT.PERMISSIONS.AUDIT_RECORDS')},
        { name: 'assign_to_me', label: this.$t('AGENT_MGMT.PERMISSIONS.ASSIGN_TO_ME')},
        { name: 'see_all_conversations', label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_ALL_CONVERSATIONS')},
        { name: 'delete_contact', label:this.$t('AGENT_MGMT.PERMISSIONS.DELETE_CONTACT')},
        { name: 'see_bot_conversation', label:this.$t('AGENT_MGMT.PERMISSIONS.SEE_BOT_CONVERSATION')},
        {name: 'delete_crm_observation', label:this.$t('AGENT_MGMT.PERMISSIONS.DELETE_CRM_OBSERVATION')},
      ],
      permissions: [],
      permissionsSelected: this.agentPermissions,
      permissionsDefault: {
        dashboard: true,
        contacts: true,
        campaigns: true,
        help_center: true,
        reports: true,
        agent_settings: true,
        team_settings: true,
        markers_settings: true,
        custom_attributes_settings: true,
        automations_settings: true,
        macros_settings: true,
        shortcuts_settings: true,
        create_global_shortcut: true,
        see_private_message: true,
        inboxes: false,
        delete_inbox: false,
        delete_agent: false,
        delete_team: false,
        integrations: false,
        applications: false,
        audit_records: false,
        assign_to_me: false,
        see_all_conversations: false,
        delete_contact: false,
        team_chat_only: true,
        see_conversations_team: false,
        see_bot_conversation: false,
        delete_crm_observation:false
      }

    };
  },
  watch:{
    'permissionsSelected.see_conversations_team'(newValue) {
      if (newValue) {
        this.permissionsSelected.see_all_conversations = false;
      }
    },
    'permissionsSelected.see_all_conversations'(newValue) {
      if (newValue) {
        this.permissionsSelected.see_conversations_team = false;
      }
    }
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentType: {
      required,
    },
    agentAvailability: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
        })
      );
    },
  },

  mounted(){
    if(this.agentType === 'supervisor'){
      this.permissions = this.permissionsSupervisor;
    }else{
      this.permissions = this.permissionsAgent;
    }
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    validatePermissions(){
      const agentPermissions = [
        'assign_to_me', 'see_all_conversations', 'shortcuts_settings', 'create_global_shortcut', 'see_private_message', 'see_conversations_team','see_bot_conversation','delete_crm_observation'
      ];
      for (let permission in this.permissionsSelected) {
        if(this.agentType === 'agent'){
          if (!agentPermissions.includes(permission)) {
            this.permissionsSelected[permission] = false;
          }
        } else if(this.agentType === 'administrator'){
          if(permission === 'team_chat_only'){
            this.permissionsSelected[permission] = false;
          }else {
            this.permissionsSelected[permission] = true;
          }
        }
      }
      
      return this.permissionsSelected;
    },

    async editAgent() {
      try {
        const permissions = this.validatePermissions();

        const payload = {
          id: this.id,
          name: this.agentName,
          role: this.agentType == 'agent' ? 'agent' : 'administrator',
          type_role: this.agentType,
          permissions: permissions,
          availability: this.agentAvailability,
          wallet_status: this.walletStatus,
        }

        await this.$store.dispatch('agents/update',payload);
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async resetPassword() {
      try {
        await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },

    changeTypeRole(e){
      const typeRole = e.target.value;
      this.permissionsSelected = JSON.parse(JSON.stringify(this.permissionsDefault));

      if(typeRole == 'agent'){
        this.permissions = this.permissionsAgent;
      }else {
        this.permissions = this.permissionsSupervisor;
      }
    }
  },


};

</script>

<style scoped>
  .full-access {
    color: #01c6d8 !important; 
  }
</style>